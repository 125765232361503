<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-primary text-center">Thank you for registering!</h1>
        <div class="text-lead">
          Please share itinerary of customers who travel between India and the USA and upload their
          tickets. NOTE: All the details shared are kept highly secured and will not be shared with
          any external parties and used only for Trepr services solicitation purposed.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
};
</script>

<style scoped></style>
